<template>
   <div>
      <canvas id="category-chart" width="100"></canvas>
   </div>
</template>

<script>
import {
   Chart,
   ArcElement,
   LineElement,
   BarElement,
   PointElement,
   BarController,
   BubbleController,
   DoughnutController,
   LineController,
   PieController,
   PolarAreaController,
   RadarController,
   ScatterController,
   CategoryScale,
   LinearScale,
   LogarithmicScale,
   RadialLinearScale,
   TimeScale,
   TimeSeriesScale,
   Decimation,
   Filler,
   Legend,
   Title,
   Tooltip,
   SubTitle,
} from "chart.js";

Chart.register(
   ArcElement,
   LineElement,
   BarElement,
   PointElement,
   BarController,
   BubbleController,
   DoughnutController,
   LineController,
   PieController,
   PolarAreaController,
   RadarController,
   ScatterController,
   CategoryScale,
   LinearScale,
   LogarithmicScale,
   RadialLinearScale,
   TimeScale,
   TimeSeriesScale,
   Decimation,
   Filler,
   Legend,
   Title,
   Tooltip,
   SubTitle
);

export default {
   name: "SectionChart",
   data: () => ({
      sectionData: {
         type: "bar",
         data: {
            labels: [
               "Mercury",
               "Venus",
               "Earth",
               "Mars",
               "Jupiter",
               "Saturn",
               "Uranus",
               "Neptune",
            ],
            datasets: [
               {
                  label: "Number of Moons",
                  data: [0, 0, 1, 2, 79, 82, 27, 14],
                  backgroundColor: "#4E6B8D",
                  borderColor: "#364F6B",
                  borderWidth: 3,
               },
               {
                  label: "Planetary Mass (relative to the Sun x 10^-6)",
                  data: [
                     0.166, 2.081, 3.003, 0.323, 954.792, 285.886, 43.662,
                     51.514,
                  ],
                  backgroundColor: "#60DFE6",
                  borderColor: "#3FC1C9",
                  borderWidth: 3,
               },
            ],
         },
         options: {
            responsive: true,
            lineTension: 1,
         },
      },
   }),
   mounted() {
      const ctx = document.getElementById("category-chart");
      new Chart(ctx, this.sectionData);
   },
};
</script>
