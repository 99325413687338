<template>
  <div>
    <v-container class="container mt-0" fluid>
      <v-card color="accent" elevation="6">
        <v-row style="margin: 10px">
          <v-col cols="6">
            <h3 class="warning-color">حسابات المستخدمين</h3>
          </v-col>

          <v-col cols="6" style="text-align: left">
            <!-- <AddAccount
                     style="display: inline-block"
                     @reloadTask="initialData()"
                  /> -->
          </v-col>
        </v-row>
      </v-card>
    </v-container>

    <v-container class="container" fluid>
      <v-card color="accent" elevation="6">
        <v-card-title class="warning-color">
          حسابات المستخدمين
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="mdi-magnify" label="بحث" single-line hide-details solo></v-text-field>
        </v-card-title>
        <v-data-table :headers="headers" :items="ItemsFilter" :items-per-page="10" class="table-content table-warning-color">
          <template v-slot:[`item.index`]="{ index }">
            <h4 style="padding: 0 3px">{{ index + 1 }}</h4>
          </template>

          <!-- <template v-slot:header="props">{{ props }}</template> -->

          <template v-slot:[`item.dailyWork`]="{ item }">
            <h5 style="display: inline-block">من : {{ item.fromTime }} &nbsp; \ &nbsp;</h5>
            <h5 style="display: inline-block">الى : {{ item.toTime }}</h5>
            <h5>العدد : {{ item.number }}</h5>
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <h5 :style="item.isVerified == 1 ? 'color:green' : 'color:red'">{{ item.isVerified == 1 ? "مفعل" : "غير مفعل" }}</h5>
          </template>

          <template v-slot:[`item.role`]="{ item }">
            <h5>{{ item.roleId == 1 ? "مسؤول" : "زبون" }}</h5>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <UserPhotos style="display: inline-block" :tid="item.tid" />
            <EditUserVisible style="display: inline-block" :user="item" @reloadTask="initialData()" />
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import UserPhotos from "./../components/UserPhotos.vue";
import EditUserVisible from "./../components/EditUserVisible.vue";
export default {
  components: {
    UserPhotos,
    EditUserVisible,
  },
  data: () => ({
    accounts: [],
    projects: [],
    search: "",
    headers: [
      { text: "التسلسل", value: "index" },
      { text: "الاسم الاول", value: "userName" },
      { text: "اسم الاب", value: "sirName" },
      { text: "رقم الهاتف", value: "phoneNumber" },
      { text: " نوع الحساب", value: "role" },
      { text: "الحالة", value: "status" },
      { text: "الاجراءات", value: "actions" },
    ],
  }),

  //    gas - food - credit - transport - general
  created() {
    this.initialData();
  },
  methods: {
    initialData() {
      let self = this;
      let userToken = localStorage.getItem("token");
      self.$http
        .get(`${self.$store.state.apiUrl}/users`, { headers: { Authorization: `Berar ${userToken}` } })
        .then((res) => {
          console.log(res.data);
          self.accounts = res.data;
        })
        .catch((err) => {
          if (err?.response?.status == 401) {
            localStorage.removeItem("bankLogin");
            location.reload();
          }
        });
    },
  },
  computed: {
    ItemsFilter() {
      if (this.search) {
        return this.accounts.filter((item) => {
          return this.search
            .toLowerCase()
            .split(" ")
            .every((v) => item.userName.toLowerCase().includes(v));
        });
      } else {
        return this.accounts;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
