<template>
  <div>
    <v-dialog v-model="show" persistent max-width="600px" width="600px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>اضافة خبر جديد </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin-top: 10px"></div>

          <v-text-field v-model="title" outlined label="عنوان الخبر" :rules="studentRequiredFormRules"></v-text-field>

          <v-file-input label="صورة الخبر" outlined v-model="files" @change="getAllFiles"></v-file-input>

          <v-textarea outlined :rules="studentRequiredFormRules" v-model="description" name="input-7-4" label="وصف الخبر"></v-textarea>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn text @click="saveData"> حفظ </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mx-2" fab small color="primary" @click="show = true" v-bind="attrs" v-on="on">
          <v-icon dark> mdi-plus </v-icon>
        </v-btn>
      </template>
      <span>اضافة خبر</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  data: () => ({
    studentRequiredFormRules: [(v) => !!v || "حقل مطلوب . يرجى ادخال قيمة"],
    show: false,
    title: "",
    description: "",
    newsImage: "",
    files: [],
  }),

  methods: {
    getAllFiles() {
      let self = this;
      let formData = new FormData();
      formData.append("files", self.files);
      self.$http.post(`${self.$store.state.apiUrl}/upload`, formData).then((res) => {
        console.log(res);
        self.newsImage = res.data;
      });
    },
    saveData() {
      let self = this;
      if (self.title && self.description && self.newsImage) {
        let data = {
          title: self.title,
          description: self.description,
          newsImage: self.newsImage.imagePath,
          createdBy: self.$store.state.userCredentials.user.idUser,
        };
        let userToken = localStorage.getItem("token");
        this.$http
          .post(`${this.$store.state.apiUrl}/addNews`, data, { headers: { Authorization: `Berar ${userToken}` } })
          .then((res) => {
            this.show = false;
            this.$toasted.success("تم اضافه البيانات");
            this.$emit("reloadTask");
          })
          .catch((err) => {
            this.$toasted.error(" حدث خطأ في البيانات");
          });
      } else {
        this.$toasted.error("الرجاء ملىء كافة الحقول");
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
