import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import Accounts from "../views/Accounts.vue";
import ATM from "../views/ATM.vue";
import News from "../views/News.vue";
import Settings from "../views/Settings.vue";
import Banner from "../views/Banner.vue";
import CustomerMessages from "../views/CustomerMessages.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/banner",
    name: "Banner",
    component: Banner,
  },
  {
    path: "/messages",
    name: "CustomerMessages",
    component: CustomerMessages,
  },
  {
    path: "/accounts",
    name: "Accounts",
    component: Accounts,
  },
  {
    path: "/news",
    name: "News",
    component: News,
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
  },
  {
    path: "/atm",
    name: "ATM",
    component: ATM,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
