import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
//apiUrl: "http://103.23.60.174/api",
export default new Vuex.Store({
  state: {
    userCredentials: null,
    apiUrl: "https://api.smartuni.app/api",
    // apiUrl: "http://localhost:4300/api",
  },
  mutations: {
    SET_USER_DATA(state, userData) {
      state.userCredentials = userData;
      localStorage.setItem("bankLogin", JSON.stringify(userData));
    },
  },
  actions: {
    login({ commit }, credentials) {
      commit("SET_USER_DATA", { ...credentials });
    },
  },
  modules: {},
});
