<template>
  <div>
    <v-dialog v-model="show" persistent max-width="600px" width="600px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>اضافة خبر جديد </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin-top: 10px"></div>

          <v-switch v-model="isVerified" :label="`الحالة : ${isVerified.toString() == 1 ? 'مفعل' : 'غير مفعل'}`"></v-switch>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn text @click="saveData"> حفظ </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-btn icon @click="show = true">
      <v-icon color="primary"> mdi-pencil </v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    user: Object,
  },
  data: () => ({
    studentRequiredFormRules: [(v) => !!v || "حقل مطلوب . يرجى ادخال قيمة"],
    show: false,
    isVerified: false,
  }),
  created() {
    console.log(this.user);
    this.isVerified = this.user.isVerified;
  },
  methods: {
    saveData() {
      let self = this;

      self.user.isVerified = self.isVerified;
      let userToken = localStorage.getItem("token");

      this.$http
        .put(`${this.$store.state.apiUrl}/user/${self.user.idUser}`, self.user, { headers: { Authorization: `Berar ${userToken}` } })
        .then((res) => {
          this.show = false;
          this.$toasted.success("تم تعديل البيانات");
          this.$emit("reloadTask");
        })
        .catch((err) => {
          this.$toasted.error(" حدث خطأ في البيانات");
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
