<template>
  <div>
    <v-dialog v-model="show" persistent fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>المرفقات</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin: 15px">
            <v-row>
              <h3 v-if="image == null">لا توجد مرفقات</h3>
              <v-col cols="12">
                <img @click="fullScreenImage(image)" :src="`${$store.state.apiUrl}/${image}`" width="100%" />
              </v-col>
            </v-row>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn text @click="show = false"> حسنا </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mx-2" icon small color="primary" @click="show = true" v-bind="attrs" v-on="on">
          <v-icon dark> mdi-eye </v-icon>
        </v-btn>
      </template>
      <span>صور المستخدم</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    image: String,
  },
  data: () => ({
    studentRequiredFormRules: [(v) => !!v || "حقل مطلوب . يرجى ادخال قيمة"],
    show: false,
  }),
  methods: {
    fullScreenImage(image) {
      let imageWindow = window.open("", "", "left=0,top=0,width=1000,height=900,toolbar=0,scrollbars=0,status=0");

      imageWindow.document.write(`<!DOCTYPE html>
                  <html>
                  <head></head>
                  <body></body> <img src="${this.$store.state.apiUrl}/${image}"  style="margin-top: 10px;"></html>`);

      setTimeout(() => {
        imageWindow.document.close();
        imageWindow.focus();
        imageWindow.print();
        imageWindow.close();
      }, 500);
      //  setTimeout(function () {
      //  }, 500);
    },
  },
};
</script>

<style lang="scss" scoped></style>
