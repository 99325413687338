<template>
  <div class="home">
    <v-row style="margin-top: 50px">
      <v-col cols="12" sm="6" md="4">
        <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 10px" max-width="344" color="accent">
          <v-icon style="background: white; margin-top: -30px" class="pa-5 elevation-6 radius-1 mb-5" color="warning"> mdi-account-group </v-icon>
          <h4 class="warning-color" style="font-size: 1.4em; font-weight: lighter">عدد المستخدمين</h4>
          <h4 class="warning-color" style="font-size: 2.5em; font-weight: lighter">
            {{ statistics.users }}
          </h4>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 10px" max-width="344" color="accent">
          <v-icon style="background: white; margin-top: -30px" class="pa-5 elevation-6 radius-1 mb-5" color="warning">mdi-atm</v-icon>
          <h4 class="warning-color" style="font-size: 1.4em; font-weight: lighter">عدد الصرافات الالية</h4>
          <h4 class="warning-color" style="font-size: 2.5em; font-weight: lighter">
            {{ statistics.atm }}
          </h4>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 10px" max-width="344" color="accent">
          <v-icon style="background: white; margin-top: -30px" class="pa-5 elevation-6 radius-1 mb-5" color="warning">mdi-newspaper-variant</v-icon>
          <h4 class="warning-color" style="font-size: 1.4em; font-weight: lighter">مجموع الاخبار</h4>
          <h4 lass="warning-color" style="font-size: 2.5em; font-weight: lighter">
            {{ statistics.news }}
          </h4>
        </v-card>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="6" class="pa-4">
        <v-card class="ma-2 radius-1 pa-5" elevation="6">
          <SectionChart />
        </v-card>
      </v-col>
      <v-col cols="6" class="pa-4">
        <v-card class="ma-2 radius-1 pa-5" elevation="6">
          <CategoryChart />
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SectionChart from "./../components/SectionChart.vue";
import CategoryChart from "./../components/CategoryChart.vue";

export default {
  components: {
    SectionChart,
    CategoryChart,
  },
  name: "Home",
  data: () => ({
    statistics: "",
  }),
  created() {
    this.initData();
  },
  methods: {
    initData() {
      let self = this;

      let userToken = localStorage.getItem("token");
      self.$http
        .get(`${self.$store.state.apiUrl}/statistics`, { headers: { Authorization: `Berar ${userToken}` } })
        .then((res) => {
          console.log(res.data);
          self.statistics = res.data;
        })
        .catch((err) => {
          if (err?.response?.status == 401) {
            localStorage.removeItem("bankLogin");
            location.reload();
          }
        });
    },
  },
};
</script>
