<template>
  <v-app>
    <v-app-bar app color="primary" dark v-if="$store.state.userCredentials != null" class="d-none d-md-block">
      <div class="d-flex align-center" style="width: 100%">
        <div>
          <v-icon large color="white">mdi-bank</v-icon>
          <span style="color: #d6e0f0">UBOI</span>
        </div>
        <v-spacer></v-spacer>
        <div>
          <v-list class="justify-space-around d-flex" dense color="primary">
            <v-list-item-group v-model="selectedItem" class="d-flex">
              <v-list-item v-for="(item, i) in items" :key="i" style="margin: 0 10px" color="white" @click="page(item)">
                <v-list-item-icon>
                  <v-icon v-text="item.icon" color="white"></v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title v-text="item.text" style="color: #ffffff"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </div>

        <v-spacer></v-spacer>
        <div>
          <v-menu rounded="lg" offset-y>
            <template v-slot:activator="{ attrs, on }">
              <v-btn elevation="0" color="primary" class="white--text" v-bind="attrs" v-on="on">
                <v-icon left color="white"> mdi-account-circle </v-icon>
                <span style="color: #d6e0f0; font-size: 12px">
                  {{ $store.state.userCredentials.userName }}
                </span>
              </v-btn>
            </template>

            <v-list style="padding: 0px">
              <v-list-item link @click="logout">
                <v-list-item-title style="color: #876445; font-size: 12px">
                  <v-icon left color="primary"> mdi-location-exit </v-icon>
                  تسجيل خروج
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
    </v-app-bar>

    <v-app-bar color="primary" dense dark class="d-block d-md-none" v-if="$store.state.userCredentials != null">
      <v-app-bar-nav-icon @click="drawer = !drawer"> </v-app-bar-nav-icon>

      <v-spacer></v-spacer>

      <v-toolbar-title>ادارة المشاريع </v-toolbar-title>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" fixed temporary right>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>القائمة</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense>
        <v-list-item v-for="item in items" :key="item.title" link @click="page(item)">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link @click="logout">
          <v-list-item-icon>
            <v-icon>mdi-location-exit</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>تسجيل خروج</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <div style="margin-top: 0px">
        <router-view />
      </div>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    drawer: null,
    selectedItem: 0,
    items: [
      { title: "/", text: "الرئيسية", icon: "mdi-home" },
      {
        title: "/accounts",
        text: "الحسابات",
        icon: "mdi-account-group",
      },
      {
        title: "/atm",
        text: "اجهزة الصرافة",
        icon: "mdi-atm",
      },
      {
        title: "/news",
        text: "الاخبار",
        icon: "mdi-newspaper-variant",
      },
      {
        title: "/banner",
        text: "الاعلانات",
        icon: "mdi-image-multiple",
      },
      {
        title: "/messages",
        text: "رسائل الزبائن",
        icon: "mdi-forum-outline",
      },
      {
        title: "/settings",
        text: "الاعدادات",
        icon: "mdi-cogs",
      },
    ],
  }),
  created() {
    let user = localStorage.getItem("bankLogin");
    if (user) {
      this.$store.state.userCredentials = JSON.parse(user);
      this.$router.push("/").catch((e) => {});
    } else {
      this.$router.push("/login").catch((e) => {});
    }
  },
  computed: {
    userName() {
      let name = localStorage.getItem("bankLogin");
      return JSON.parse(name).username;
    },
  },
  methods: {
    page(item) {
      this.$router.push(item.title).catch(() => {});
    },
    logout() {
      localStorage.removeItem("bankLogin");
      location.reload();
    },
    parseJwt(token) {
      var base64Url = token.split(".")[1];
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(Buffer.from(base64, "base64"));
      return JSON.parse(jsonPayload);
    },
  },
};
</script>
<style>
@import url("./font.css");
</style>
