<template>
  <div>
    <v-dialog v-model="show" persistent max-width="600px" width="600px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>اضافة صراف جديد</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin-top: 10px"></div>

          <v-text-field v-model="atmName" outlined label="اسم الصراف الالية" :rules="studentRequiredFormRules"></v-text-field>

          <v-text-field v-model="atmAddress" outlined label="العنوان" :rules="studentRequiredFormRules"></v-text-field>

          <v-text-field v-model="supportNumber" outlined label="رقم هاتف الدعم" :rules="studentRequiredFormRules"></v-text-field>

          <v-text-field v-model="lang" outlined type="number" label="خط الطول" :rules="studentRequiredFormRules"></v-text-field>

          <v-text-field v-model="lat" outlined type="number" label="خط العرض" :rules="studentRequiredFormRules"></v-text-field>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn text @click="saveData"> حفظ </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mx-2" fab small color="primary" @click="show = true" v-bind="attrs" v-on="on">
          <v-icon dark> mdi-plus </v-icon>
        </v-btn>
      </template>
      <span>اضافة صراف جديد</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  data: () => ({
    studentRequiredFormRules: [(v) => !!v || "حقل مطلوب . يرجى ادخال قيمة"],
    show: false,
    categoryName: "",
    atmAddress: "",
    supportNumber: "",
    lang: "",
    lat: "",
    atmName: "",
  }),
  methods: {
    saveData() {
      let self = this;

      if (self.atmAddress && self.atmName && self.lang && self.lat && self.supportNumber) {
        let data = {
          atmName: self.atmName,
          address: self.atmAddress,
          supportNumber: self.supportNumber,
          lang: self.lang,
          lat: self.lat,
          createdBy: self.$store.state.userCredentials.user.idUser,
        };
        let userToken = localStorage.getItem("token");

        this.$http
          .post(`${this.$store.state.apiUrl}/addAtmLocation`, data, { headers: { Authorization: `Berar ${userToken}` } })
          .then((res) => {
            this.show = false;
            this.$toasted.success("تم اضافه البيانات");
            this.$emit("reloadTask");
          })
          .catch((err) => {
            this.$toasted.error(" حدث خطأ في البيانات");
          });
      } else {
        this.$toasted.error("الرجاء ملىء كافة الحقول");
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
