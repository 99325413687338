<template>
  <div>
    <v-container class="container mt-0" fluid>
      <v-card color="accent" elevation="6">
        <v-row style="margin: 10px">
          <v-col cols="6">
            <h3 class="warning-color">الاعلانات</h3>
          </v-col>

          <v-col cols="6" style="text-align: left">
            <AddBanner style="display: inline-block" @reloadTask="initialData()" />
          </v-col>
        </v-row>
      </v-card>
    </v-container>

    <v-container class="container" fluid>
      <v-card color="accent" elevation="6">
        <v-card-title class="warning-color">
          الاخبار
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="mdi-magnify" label="بحث" single-line hide-details solo></v-text-field>
        </v-card-title>
        <v-data-table :headers="headers" :items="ItemsFilter" :items-per-page="10" class="table-content table-warning-color">
          <template v-slot:[`item.index`]="{ index }">
            <h4 style="padding: 0 3px">{{ index + 1 }}</h4>
          </template>

          <!-- <template v-slot:header="props">{{ props }}</template> -->

          <template v-slot:[`item.dailyWork`]="{ item }">
            <h5 style="display: inline-block">من : {{ item.fromTime }} &nbsp; \ &nbsp;</h5>
            <h5 style="display: inline-block">الى : {{ item.toTime }}</h5>
            <h5>العدد : {{ item.number }}</h5>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <NewsPhoto :image="item.photoPath" style="display: inline-block" />

            <v-btn icon @click="dialog = false" style="display: inline-block">
              <v-icon @click="deleteItem(item)" color="primary"> mdi-delete </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import AddBanner from "./../components/AddBanner.vue";
import NewsPhoto from "./../components/NewsPhoto.vue";
export default {
  components: {
    AddBanner,
    NewsPhoto,
  },
  data: () => ({
    accounts: [],
    projects: [],
    search: "",
    headers: [
      { text: "التسلسل", value: "index" },
      { text: "الرابط", value: "bannerLink" },
      { text: "تاريخ الخبر", value: "bannerDateFormatter" },
      { text: "الاجراءات", value: "actions" },
    ],
  }),

  created() {
    this.initialData();
  },
  methods: {
    initialData() {
      let self = this;
      let userToken = localStorage.getItem("token");
      self.$http
        .get(`${self.$store.state.apiUrl}/banners`, { headers: { Authorization: `Berar ${userToken}` } })
        .then((res) => {
          console.log(res.data);
          self.accounts = res.data;
        })
        .catch((err) => {
          if (err?.response?.status == 401) {
            localStorage.removeItem("bankLogin");
            location.reload();
          }
        });
    },
    deleteItem(item) {
      let self = this;
      let userToken = localStorage.getItem("token");
      self.$http
        .delete(`${self.$store.state.apiUrl}/banner/${item.idBanner}`, {
          headers: { Authorization: `Bearer ${userToken}` },
        })
        .then((res) => {
          console.log(res.data);
          this.initialData();
        });
    },
  },
  computed: {
    ItemsFilter() {
      if (this.search) {
        return this.accounts.filter((item) => {
          return this.search
            .toLowerCase()
            .split(" ")
            .every((v) => item.userName.toLowerCase().includes(v));
        });
      } else {
        return this.accounts;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
